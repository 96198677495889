import React, {useEffect, useState} from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Flex,
  HStack,
  Code,
  Input,
  Grid,
  theme,
  Divider,
  Button,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import ShareComponent from './ShareComponent';

function App() {

  const [selectedState, setSelectedState] = useState("");
  const [states, setStates] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showScript, setShowScript] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [zipCode, setZipCode] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [moreForOne, setMoreForOne] = useState(false);
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [works, setWorks] = useState(false);

  useEffect(() => {
    // check if there is a way to determine which day it is

    const states = [
      {
        "name": "Alabama",
        "code": "AL"
      },
      {
        "name": "Alaska",
        "code": "AK"
      },
      {
        "name": "Arizona",
        "code": "AZ"
      },
      {
        "name": "Arkansas",
        "code": "AR"
      },
      {
        "name": "California",
        "code": "CA"
      },
      {
        "name": "Colorado",
        "code": "CO"
      },
      {
        "name": "Connecticut",
        "code": "CT"
      },
      {
        "name": "Delaware",
        "code": "DE"
      },
      {
        "name": "Florida",
        "code": "FL"
      },
      {
        "name": "Georgia",
        "code": "GA"
      },
      {
        "name": "Hawaii",
        "code": "HI"
      },
      {
        "name": "Idaho",
        "code": "ID"
      },
      {
        "name": "Illinois",
        "code": "IL"
      },
      {
        "name": "Indiana",
        "code": "IN"
      },
      {
        "name": "Iowa",
        "code": "IA"
      },
      {
        "name": "Kansas",
        "code": "KS"
      },
      {
        "name": "Kentucky",
        "code": "KY"
      },
      {
        "name": "Louisiana",
        "code": "LA"
      },
      {
        "name": "Maine",
        "code": "ME"
      },
      {
        "name": "Maryland",
        "code": "MD"
      },
      {
        "name": "Massachusetts",
        "code": "MA"
      },
      {
        "name": "Michigan",
        "code": "MI"
      },
      {
        "name": "Minnesota",
        "code": "MN"
      },
      {
        "name": "Mississippi",
        "code": "MS"
      },
      {
        "name": "Missouri",
        "code": "MO"
      },
      {
        "name": "Montana",
        "code": "MT"
      },
      {
        "name": "Nebraska",
        "code": "NE"
      },
      {
        "name": "Nevada",
        "code": "NV"
      },
      {
        "name": "New Hampshire",
        "code": "NH"
      },
      {
        "name": "New Jersey",
        "code": "NJ"
      },
      {
        "name": "New Mexico",
        "code": "NM"
      },
      {
        "name": "New York",
        "code": "NY"
      },
      {
        "name": "North Carolina",
        "code": "NC"
      },
      {
        "name": "North Dakota",
        "code": "ND"
      },
      {
        "name": "Ohio",
        "code": "OH"
      },
      {
        "name": "Oklahoma",
        "code": "OK"
      },
      {
        "name": "Oregon",
        "code": "OR"
      },
      {
        "name": "Pennsylvania",
        "code": "PA"
      },
      {
        "name": "Rhode Island",
        "code": "RI"
      },
      {
        "name": "South Carolina",
        "code": "SC"
      },
      {
        "name": "South Dakota",
        "code": "SD"
      },
      {
        "name": "Tennessee",
        "code": "TN"
      },
      {
        "name": "Texas",
        "code": "TX"
      },
      {
        "name": "Utah",
        "code": "UT"
      },
      {
        "name": "Vermont",
        "code": "VT"
      },
      {
        "name": "Virginia",
        "code": "VA"
      },
      {
        "name": "Washington",
        "code": "WA"
      },
      {
        "name": "West Virginia",
        "code": "WV"
      },
      {
        "name": "Wisconsin",
        "code": "WI"
      },
      {
        "name": "Wyoming",
        "code": "WY"
      }
    ];
    // console.log("states", states);  
    var thisStates = [];
    for(var i = 0; i < states.length; i++){
      thisStates.push(states[i]["name"]);
    }
    setStates(thisStates);


  }, []);

  useEffect(() => {
    let varlist = [selectedState, firstName, lastName, zipCode, city, address, phoneNumber];
    var localWorks = true;
    for(var i = 0; i < varlist.length; i++){
      if(varlist[i].length === 0){
        localWorks = false;
      }
    }
    setWorks(localWorks);
  }, [selectedState, firstName, lastName, zipCode, city, address, phoneNumber]);
  return (
    <ChakraProvider theme={theme}>
      {/* <ScrollToBottom> */}
        <Box textAlign="left" fontSize="xl">
          <Grid p={3}>
            <ColorModeSwitcher justifySelf="flex-end" />
              <VStack spacing={8}>
              <Box width='100%' maxWidth='md'>

                <center><Text fontSize={100}>🇺🇦</Text></center>
                
                <Text fontSize={12}>This is an effort to make it easy for citizens to call on the White House for action on Ukraine.</Text>
                <Text margin={5} fontSize={14}>Ask the President to <br/> • help refugees resettle in the United States <br/> • help EU countries that help Ukraine refugees <br/> • help Europe fuel and feed themselves without Russia.</Text>

                  <Box>

                    <Box shadow='sm' p='6' maxWidth='md' borderWidth='2px' overflow='hidden' borderRadius='lg' margin={6}>

                     <Text marginTop={6} fontSize={15}>What is your first name?</Text>
                      <Input placeholder="Lottie" value={firstName} onChange={(text) => setFirstName(text.target.value)}></Input>
                      <Text marginTop={6} fontSize={15}>What is your last name?</Text>
                      <Input placeholder="Wiseacre" value={lastName} onChange={(text) => setLastName(text.target.value)}></Input>

                      <Text marginTo={6} fontSize={15}>What is your phone number?</Text>
                      <Input value={phoneNumber} placeHolder="(123) 456-7890" onChange={(text) => setPhoneNumber(text.target.value)}></Input>

                      <Text marginTop={6} fontSize={15}>What is your address?</Text>
                      <Input value={address} placeholder="1234 ABC St." onChange={(text) => setAddress(text.target.value)}></Input>

                      <Text marginTo={6} fontSize={15}>What is your zip code?</Text>
                      <Input value={zipCode} placeHolder="12345" onChange={(text) => setZipCode(text.target.value)}></Input>

                      <Text marginTo={6} fontSize={15}>What city do you live in?</Text>
                      <Input value={city} placeHolder="Kansas City" onChange={(text) => setCity(text.target.value)}></Input>
                      
                      <Text marginTop={6} fontSize={15}>Which state do you live in?</Text>
                      <AutoComplete value={selectedState} onChange={(text) => setSelectedState(text)} openOnFocus>
                        <AutoCompleteInput variant="filled"/>
                        <AutoCompleteList>
                          {states.map((state, sid) => (
                            <AutoCompleteItem
                              key={`option-${sid}`}
                              value={state}
                              textTransform="capitalize"
                            >
                              {state}
                            </AutoCompleteItem>
                          ))}
                        </AutoCompleteList>
                      </AutoComplete>
                          
                      <Text fontSize={12}>Please complete all fields before submitting.</Text>
                      <Text fontSize={12}>Upon clicking send, your response will be stored through Google Forms.</Text>
                      <center><Button disabled={!works} borderRadius={'lg'} borderWidth={2} borderColor={"#FFD500"}  marginTop={4} onClick={() => setShowScript(true)}> 
                        {works &&
                          <Link href={`https://docs.google.com/forms/d/e/1FAIpQLScB3cXjN0ohq07EpvuhoWhuOnpyS9sg5Uibxj_DkWYpy-_BsA/formResponse?usp=pp_url&entry.1416685982=${firstName}&entry.1745570048=${lastName}&entry.782754474=${phoneNumber}&entry.1136641087=${selectedState}&entry.1807384897=${address}&entry.1068167395=${zipCode}&submit=Submit`}> Send </Link> 
                        }
                        {!works &&
                          <Text>Send</Text>
                        }
                      </Button></center>
                          
                      <center><Button marginBottom={4} marginTop={4} borderRadius={'lg'} borderWidth={2} borderColor={"#FFD500"} onClick={() => setShowShare(!showShare)}>{showShare ? 'Close' : 'Share'}</Button></center>
                      {showShare && <ShareComponent/>}
                    </Box>
                  </Box>
              </Box>
            </VStack>
          </Grid>


        </Box>
      {/* </ScrollToBottom> */}

    </ChakraProvider>
  );
}



export default App;
